.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.app-nav-link,
.app-nav-text {
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
}

.app-nav-link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.app-nav-button {
  background: #118DF0;
  color: white;
  padding: 5px;
  display: inline-block;
  border-radius: 4px;
  font-weight: bold;
}

.editor {
  padding: 20px;
  font-size: 16px;
  text-align: left;
  min-height: 300px;
  border: 1px solid tomato;
  border-radius: 5px;
}
.block {
  background: tomato;
  color: white;
}
.align-middle {
  display: inline-block;
  vertical-align: middle;
}