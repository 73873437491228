
:root {
  --primary: #118DF0;
  --secondary: #004182;
  --tertiary: #FF4B68;
  --accent: #FBFFA3;
  --grayscale: #888888;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 500;
}

.app-main {
  padding: 20px;
}
.app-header {
  margin-bottom: 10px;
}
.app-heading {
  vertical-align: middle;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
}
.app-logo {
  vertical-align: middle;
  max-width: 50px;
}

.right {
  float: right;
}

.btn {
  padding: 10px 20px;
  background: black;
  color: white;
  border: 1px solid black;
  border-radius: 0;
  font-size: 14px;
}
.editor-input {
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid black;
}
.select-input {
  border: 1px solid black;
  border-radius: 0;
  font-size: 14px;
  -webkit-appearance: none;
  padding: 10px 20px;
}

.editor-screen {
  margin-bottom: 150px;
}

.editor-control {
  margin-bottom: 10px;
}
.editor-controls {
  margin-bottom: 30px;
}
.editor-section-wrapper {
  position: relative;
}
.editor-title-input {
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--secondary);
}
.editor-viewer {
  padding: 20px;
  font-size: 16px;
  text-align: left;
  min-height: 300px;
  margin-bottom: 30px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  caret-color: black; /* var(--primary); */
  line-height: 3;
}
.editor-viewer div {
  /* Hack to make the caret on return key move into the created div */
  min-height: 30px;
  min-width: 1px;
}
.editor-viewer::selection, .editor-viewer *::selection{
  background: var(--tertiary);
  color: white;
}
.editor-viewer:focus {
  outline: none;
}
.editor-viewer br {
  display: none;
}
.editor-block {
  position: relative;
  background: var(--primary);
  color: white;
  user-select: none;
  /* cursor: pointer; */
  border-radius: 2px;
  padding: 3px 0;
  transition: all .3s ease;
}
.editor-block[data-lyric-chord=minor] {
  background: var(--secondary);
}
.editor-block[data-lyric-key]:hover {
  opacity: 1;
}
.editor-block[data-lyric-key^='C'] {
  opacity: .95;
}
.editor-block[data-lyric-key^='D'] {
  opacity: .9;
}
.editor-block[data-lyric-key^='E'] {
  opacity: .8;
}
.editor-block[data-lyric-key^='F'] {
  opacity: .7;
}
.editor-block[data-lyric-key^='G'] {
  opacity: .6;
}
.editor-block[data-lyric-key^='A'] {
  opacity: .5;
}
.editor-block[data-lyric-key^='B'] {
  opacity: .4;
}
.editor-block::before{
  content: attr(data-lyric-key) attr(data-lyric-chord);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: black;
  padding: 1px;
  font-style: italic;
  font-size: 13px;
  line-height: 1;
}
.editor-block:hover::before {
  overflow: unset;
}
.editor-block[data-lyric-display-name]::before {
  content: attr(data-lyric-display-name);
}

.viewer-section {
  margin-bottom: 50px;
}
.viewer-section-title {
  font-size: 24px;
  font-weight: 700;
}
.viewer-section-wrapper {
  padding: 20px 0;
  font-size: 21px;
  text-align: left;
  line-height: 2.8;
  transition: all .3s ease;
  /* font-weight: 600; */
}
.viewer-block {
  position: relative;
}
.viewer-block::before{
  content: attr(data-lyric-display-name);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  color: var(--grayscale);
  padding: 1px;
  font-size: 15px;
  line-height: 1;
  transition: all .3s ease;
}

.chords-mode .viewer-section-wrapper {
  font-size: 14px;
  line-height: 5;
  color: var(--grayscale);
}
.chords-mode .viewer-block::before {
  font-size: 21px;
  color: black;
}

.lyric-item-card {
  display: flex;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #eee, 0 0 4px #eee;
  transition: all .3s ease;
}
.lyric-item-card:hover {
  box-shadow: inset 0 0 0 1px var(--primary), 0 0 20px rgba(100, 100, 100, .2);
}
.lyric-item__media {
  width: 50px;
  margin-right: 15px;
}
.lyric-item__content {
  flex: 1;
}

.loading-bg {
  background: rgba(255, 255, 255, .95);
  z-index: 5;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  height: 10px;
  background: var(--primary);
  border-radius: 5px;
  -webkit-animation: load 1.8s ease-in-out infinite;
          animation: load 1.8s ease-in-out infinite;
}
.loader:before, .loader:after {
  position: absolute;
  display: block;
  content: "";
  -webkit-animation: load 1.8s ease-in-out infinite;
          animation: load 1.8s ease-in-out infinite;
  height: 10px;
  border-radius: 5px;
}
.loader:before {
  top: -20px;
  left: 10px;
  width: 40px;
  background: var(--secondary);
}
.loader:after {
  bottom: -20px;
  width: 35px;
  background: var(--tertiary);
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  50% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
}

@keyframes load {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  50% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
}

.editor-popper-menu {
  display: none;
  position: relative;
  z-index: 2;
  background: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.editor-section-wrapper:focus-within .editor-popper-menu__mobile-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.editor-popper-menu__inner {
  display: flex;
}
.editor-popper-menu__column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 5px;
}
.editor-popper-menu__column-title {
  font-size: 12px;
}
.editor-popper-menu__control {
  margin: 0;
  height: 35px;
  line-height: 1;
  font-weight: normal;
  cursor: pointer;
}

@media screen and (min-width: 480px) {
  .editor-section-wrapper:focus-within .editor-popper-menu {
    display: block;
  }
  .editor-section-wrapper:focus-within .editor-popper-menu__mobile-fixed {
    display: none;
  }
}

/* *********************** */
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.react-tagsinput--focused {
  border-color: var(--primary);
}

.react-tagsinput-tag {
  background-color: var(--secondary);
  border-radius: 2px;
  border: 1px solid var(--secondary);
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
